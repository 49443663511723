/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================
<
* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import Waiters from "assets/images/waiters.png";
import DeliveryPicture from "assets/images/delivery.png";
import EventManagementPicture from "assets/images/eventManagement.png";

function Information() {
  return (
    <MKBox component="section" py={12}
    sx={{"background": "linear-gradient(195deg, #df9d63, #dfac80) !important"}}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Waiters}
              title="Staff & Equipment"
              description={"Relax and enjoy your event knowing that every detail is taken care by our dedicated professional waiters, they handle all aspects, including setup, service, and dismantling.                         Equip your event with our wide range of furniture, chinaware, glassware, cutlery, AV systems and devices..."}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={DeliveryPicture}
              title="Delivery"
              description="We provide 24/7 delivery across the UAE using chiller trucks to maintain food freshness and safety."
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={EventManagementPicture}
              title="Planning and Logistic"
              description="Entrust us with the planning and logistics of your events and experience seamless organization.
Our team of professionals handles every detail with unmatched precision and creativity.
With 15 years of experience in the field, we have the skills necessary to turn your ideas into reality.
We work closely with you to understand your needs and create a bespoke event that reflects your vision."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
