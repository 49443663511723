/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

// Routes
// import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import Marie from "assets/images/Marie.jpeg";
import LandingPagePicture from "assets/images/landingPage.png";
import BreakfastPicture from "assets/images/Breakfast.png";
import WarmMealPicture from "assets/images/WarmMeal.png";
import ColdMealPicture from "assets/images/ColdMeal.png";
import FingerFoodPicture from "assets/images/FingerfoodPicture.jpg";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import IconWhatsapp from "assets/images/WhatsApp-Logo.png";
import { Link } from "react-router-dom";

function AboutUs() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${LandingPagePicture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
          <Grid
            container
            xs={12}
            lg={8}
            sm={3}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", height: "", padding:0}}
          >
            <MKTypography
              variant="h1"
              mt={5}
              sm={12}
              sx={[
                { fontSize: "5.9vw" },
                { color: "#ffffff" },
              ]}
              style={{ "font-family": "'Pacifico', sans-serif" }}
            >
              Cuisine Crew
            </MKTypography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={5}
            mt={6}
            sm={3}
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginBottom:"110px"}}
            justifyContent="center"
          >
            <MKTypography
              variant="h5"
              color="white"
              mt={1}
              mb={1}
              style={{ "font-family": "'Poppins', sans-serif" }}
              sx={{ color: "#393869" }}
            >
              Fuel your technical and logistical teams with the best catering services to make your
              event a memorable one in the UAE.
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={2}
              mb={1}
              style={{ "font-family": "'Poppins', sans-serif" }}
              sx={{ color: "#393869" }}
            >
              The success of an event largely relies on the wellbeing of the staff who work
              tirelessly to make it happen. That&apos;s why we prioritize delivering fresh and
              generous products, providing a relaxation room, and ensuring our staff is attentive to
              your needs. Our mission is to enable your team to focus solely on their work. Cuisine
              Crew dedicated to serving a motivated and committed workforce.
            </MKTypography>
          </Grid>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          background: "linear-gradient(to right bottom, #fcd5b5, #ffffe8)",
        }}
      >
        <MKBox component="section" py={2}>
          <Container>
            <Grid container spacing={3} justifyContent="center" style={{ align: "center" }}>
              <Grid item xs={12} sm={6} lg={3} sx={{ height: "100%" }}>
                <RotatingCard>
                  <RotatingCardFront
                    image={BreakfastPicture}
                    title={<>CRAFT TABLE</>}
                    color="light"
                    description=""
                  />
                  <RotatingCardBack
                    image="BreakfastPicture"
                    title="Craft table"
                    description="Enjoy our breakfast, coffee break, and permanent snack buffet with a variety of pastries, healthy snacks, fruits, and treats."
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <RotatingCard>
                  <RotatingCardFront
                    image={WarmMealPicture}
                    title={<>WARM MEAL</>}
                    color="light"
                    description=""
                  />
                  <RotatingCardBack
                    title="Warm meal"
                    description="Enjoy our warm meal featuring a generous salad, a main dish to share, and a delicious dessert.
Each menu comes with bread, sustainable tableware, and cutlery."
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <RotatingCard>
                  <RotatingCardFront
                    image={ColdMealPicture}
                    title={<>COLD MEAL</>}
                    color="light"
                    description=""
                  />
                  <RotatingCardBack
                    image="BreakfastPicture"
                    title="Cold meal"
                    description="Enjoy our refreshing meal, which includes a generous salad, a sandwich, or a savoury tart, and a delicious dessert.
Each menu comes with bread, sustainable tableware, and cutlery.
We can provide the meal in individual boxes."
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <RotatingCard>
                  <RotatingCardFront
                    image={FingerFoodPicture}
                    title={<>FINGER FOOD</>}
                    color="light"
                    description=""
                  />
                  <RotatingCardBack
                    title="Finger food"
                    description="Enjoy our handmade mini bites, designed to be easily eaten while standing. We offer a variety of savoury options, both cold and warm, as well as mini sweet treats. The quantity and selection will depend on what you need and the context of your event. Let us guide you and propose a suitable offering for you."
                  />
                </RotatingCard>
              </Grid>
              <MKTypography
                  variant="body"
                  color="white"
                  textAlign="center"
                  style={{ "font-family": "'Poppins', sans-serif" }}
                  sx={{ color: "#393869" }}
                >
                  We offer a wide range of products suitable for all diets. 
                  <br/>Please don&apos;t hesitate to let us know if you have any allergies, and we will customise the selection accordingly.
                </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Information />
        <Featuring />
        <Team />
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12} lg={6} alignItems="center" justifyContent="center">
            <MKBox
              display={{ xs: "none", lg: "flex" }}
              width="530px"
              height="628px"
              borderRadius="lg"
              ml={8}
              mt={2}
              sx={{ backgroundImage: `url(${Marie})` }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
            justifyContent="center"
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={1}
            >
              <MKBox
                variant="gradient"
                bgColor="dark"
                coloredShadow="info"
                borderRadius="lg"
                p={1}
                mx={2}
                mt={-3}
              >
                <MKTypography
                  variant="h3"
                  color="white"
                  textAlign="center"
                  style={{ "font-family": "'Poppins', sans-serif" }}
                >
                  Contact us
                </MKTypography>
              </MKBox>
              <MKBox p={3} textAlign="center">
                <MKTypography
                  variant="body2"
                  color="text"
                  mb={3}
                  style={{ "font-family": "'Poppins', sans-serif" }}
                >
                  Give us your brief
                  <br />
                  <br />
                  Get your quote,
                  <br />
                  As an expert in staff catering, we select for you the best solutions for your
                  events..
                  <br />
                  <br />
                  We are at your service, see you soon!
                  <br />
                  <br />
                </MKTypography>
                <form ref={form} onSubmit={sendEmail} >
                <MKBox width="100%" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        name="from_name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="text"
                        variant="standard"
                        label="Phone number"
                        name="from_tel"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        name="from_email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    
                    <Grid item xs={12} md={12}>
                      <MKInput
                        variant="standard"
                        label="What can we help you?"
                        placeholder="Describe your request"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        name="message"
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton type="submit" variant="gradient" color="dark">
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
                </form>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item 
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
          justifyContent="center">
            <MKBox justifyContent="center" alignItems="center" textAlign="center" style={{"margin-top": "-100px"}}>
              <MKTypography component="a" variant="a" color="#393869" style={{ "font-family": "'Poppins', sans-serif" }} columns={{xs: 4}}>
              <strong>Mail : <a href="mailto:contact@cuisine-crew.com" title="mail" style={{"word-wrap":"break-word"}}>contact@cuisine-crew.com</a></strong>
              </MKTypography>
              <br />
              <MKTypography component="a" variant="a" color="#393869" style={{ "font-family": "'Poppins', sans-serif" }}>
              <strong>Contact number <a href="https://wa.me/+971585379217"><img src={IconWhatsapp} alt="whatsapp logo" style={{"width":"55px"}}/></a>: <a href="tel:+971 58 537 9217" title="phone number">058 537 9217</a></strong>
              </MKTypography>
              <br />
              <MKTypography component="a" variant="h6" color="#393869" style={{ "font-family": "'Poppins', sans-serif" }}>
              <Link to="/privacy-policies" title="privacy policies">Privacy policies</Link>
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default AboutUs;
