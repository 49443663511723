import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PrivacyPolicies() {
  return (
    <>
       <Grid
            container
            item
            xs={12}
            lg={5}
            mt={6}
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginBottom:"110px"}}
            justifyContent="center"
          >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKTypography color="#393869" style={{ "font-family": "'Poppins', sans-serif" }} columns={{xs: 4}}>
            Welcome to the website of Cuisine Crew, an Emirati company established in 2023. By using this website, you agree to comply with the following terms and conditions (&ldquo;Terms&ldquo;). 
            <br />These Terms, along with our Privacy Policy, govern the relationship between Cuisine Crew and you in relation to this website. If you disagree with our terms, please don&apos;t use our website.
<br /><br />
Definitions:
<br />
&ldquo;Cuisine Crew,&ldquo; &ldquo;us,&ldquo; or &ldquo;we&ldquo; refers to the owner of the website.
&ldquo;You&ldquo; refers to the visitor or user of our website.<br /><br />
Terms of Use:
<br />
All information and content on this website are for general information only and may change without prior notice.
We use cookies to understand user preferences. If you accept cookies, the information might be used by us or third-party vendors.
We offer no guarantee on the accuracy or suitability of the information or resources presented on this website.
By using this website, you acknowledge that information may contain errors, and we exclude liability for such inaccuracies to the fullest extent permitted by law.
Your use of information or materials from this website is at your own risk. We are not liable for any consequences.
All website content, including graphics, design, and layout, is either owned by or licensed to Cuisine Crew. Reproduction is strictly prohibited unless permitted by copyright notice.
Trademarks displayed on this website, not owned or licensed by Cuisine Crew, are acknowledged. Unauthorized use of this website may be a criminal offence.
We may provide links to external third-party websites for your convenience. These links do not imply endorsement of their businesses. We are not responsible for the content of those websites.
<br /><br />Governing Law:
<br />
Your use of this website and any dispute arising from such use is subject to the laws of the United Arab Emirates.
<br /><br />
Changes to the Terms:
<br />
We reserve the right to modify these Terms at any time. Your continued use of the website signifies your acceptance of the revised Terms.
<br /><br />
Contact Us:
<br />
If you have any questions regarding these Terms, please contact us.
<br /><br />
This revised version replaces the previous terms and conditions referring to &quot;Cuisine Crew.&quot; It reflects the specific details of your company, Cuisine Crew, and removes references to unrelated legal jurisdictions.
            </MKTypography>
          </MKBox>
          </Grid>
    </>
  );
}

export default PrivacyPolicies;
