/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import logoAuditoire from "assets/images/logo-auditoire.png";
import logoLaModeEnImages from "assets/images/logoLaModeEnImage.png";
import logoLuxuryMakers from "assets/images/luxuryMakersAuditoire.png";
import logoMataFilms from "assets/images/mataFilms.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{"background": "linear-gradient(195deg, #df9d63, #dfac80) !important"}}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography
              variant="h3"
              color="white"
              style={{ "font-family": "'Poppins', sans-serif" }}
            >
              They trust us
            </MKTypography>
            <MKTypography
              variant="body2"
              color="white"
              opacity={0.8}
              style={{ "font-family": "'Poppins', sans-serif" }}
            >
              They trust us for our products and services
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={logoAuditoire}
                name="ACADEMY OF HAIR"
                position={{ color: "info", label: "Corporate" }}
                description="Academy Of Hair is located in Al Quoz, Dubai. It aims to develop the skills and knowledge of its audience to become the best professional hairstylists. The academy frequently holds community events."
                style={{ "font-family": "'Poppins', sans-serif" }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={logoLaModeEnImages}
                name="LA MODE EN IMAGES"
                position={{ color: "info", label: "Event production" }}
                description="La Mode en Images expresses its unique know-how and mobilizes its demands in the creation and production of memorable events"
                style={{ "font-family": "'Poppins', sans-serif" }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={logoMataFilms}
                name="MATA FILMS"
                position={{ color: "info", label: "Film production" }}
                description="Mata Films is the leading Green & Sustainable film and video production company based in Dubai, UAE."
                style={{ "font-family": "'Poppins', sans-serif" }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={logoLuxuryMakers}
                name="LUXURY MAKERS BY AUDITOIRE"
                position={{ color: "info", label: "Event production" }}
                description="Luxury makers is a global luxury brand experience agency that transforms conversations and enhances the interaction between organizations, brands and their community."
                style={{ "font-family": "'Poppins', sans-serif" }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
